.skranji-regular {
  font-family: "Skranji", system-ui;
  font-weight: 400;
  font-style: normal;
}

.skranji-bold {
  font-family: "Skranji", system-ui;
  font-weight: 700;
  font-style: normal;
}

.font-size-xxl {
  font-size: 4rem !important;
}

.font-size-l {
  font-size: 1.5rem !important;
}

.custom-bg-dark-gradient {
  background-image: linear-gradient(#23252f,#64666d) !important;
}

.custom-bg-dark-gradient2 {
  background-image: linear-gradient(#64666d, #23252f) !important;
}

.custom-bg-dark {
  background-color: #23252f;
}

.custom-bg-card {
  background-color: #343847 !important;
}

.custom-primary-text {
  color: #f2f2f2 !important;
}

.custom-accent-text {
  color: #bbfffa !important;
}

.custom-accent-muted-text {
  color: #71ccc6 !important;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
  background-color: #23252f;
  
}

.fullscreen {
  height: 100vh !important;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 3rem;
  }

.navbar-dim {
  height: 4rem;
  padding-top: 0 !important;
}

.delete-lr-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.App {
  text-align: center;
}

.mt-xxxl {
  margin-top: 20rem;
}

.mt-xxl {
  margin-top: 14rem;
}

.mt-xl {
  margin-top: 7rem;
}

.prf-pic {
  border-radius: 20%;
  height: 3.5rem;
}

.social-link {
  font-size: 2rem;
  color: #f2f2f2;
}

.social-link:hover {
  color: #747474 !important;
}

.arrow {
  font-size: 2rem;
  color: #f2f2f2;
}

.arrow:hover {
  color: #747474 !important;
  background-color: #bbfffa;
  opacity: 20%;
}

.project-image {
  height: auto !important; 
  width: auto !important; 
  max-width: 100% !important; 
  max-height: 350px !important;
  min-height: 350px !important;
}

.project-card {
  max-width: 100%;
  min-width: 300px;
  max-width: 900px;
}

.project-card-container {
  flex-grow: 1;
}